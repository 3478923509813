import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InitialState {
  orderData?: any;
  userInfo?: any;
}

const initialState: InitialState = {
  orderData: {},
  userInfo: {},
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrderData(state, action: PayloadAction<any>): void {
      state.orderData = action.payload;
    },
    setUserInfo(state, action: PayloadAction<any>): void {
      state.userInfo = action.payload;
    },
  },
});

const { reducer: orderReducer, actions: orderActions } = orderSlice;

export const { setOrderData, setUserInfo } = orderActions;

export default orderReducer;
